/**
 * Check if the productInfo is expired based on the date
 *
 * @param productInfo
 * @returns {boolean}
 */
const checkIfProductInfoIsExpired = (productInfo) => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let validTo = new Date(productInfo.validTo);
  return validTo < today;
};

/**
 * Check if the productInfo is planned based on the date
 *
 * @param productInfo
 * @returns {boolean}
 */
const checkIfProductInfoIsPlanned = (productInfo) => {
  // if validFrom/validTo are not defined, the productInfo is planned
  if (!productInfo.validFrom || !productInfo.validTo) {
    return true;
  }
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let validFrom = new Date(productInfo.validFrom);
  validFrom.setHours(0, 0, 0, 0);
  return validFrom > today;
};

module.exports = {
  checkIfProductInfoIsExpired,
  checkIfProductInfoIsPlanned,
}
