<template>
  <v-card>
    <v-img
      v-if="productInfoImage.imageSrc"
      :src="productInfoImage.imageSrc"
      max-height="100"
    ></v-img>
    <v-card-title>{{ getProductInfoTitle }}</v-card-title>
    <v-card-text>
      <mex-sperm-spinner
        v-if="isLoading"
        :spinnerText="loadingText"
      />
      <template v-if="!isLoading && !editable">
        <v-row>
          <v-col cols="12">
            <div class="text-body-1"> ProductInfo HyperLink: <a
              :href="productInfo.hyperlink"
              class="text-body-1"
              rel="noopener noreferrer"
              target="'_blank"
            >{{ productInfo.hyperlinkName }}</a></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="productInfo.description"
              :readonly="!editable"
              dense
              hide-details
              label="ProductInfo Description"
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row justify="start">
          <v-col align-self="center" cols="10">
            <div v-if="productInfo.validFrom && productInfo.validTo" class="text-body-1">Valid from the {{ productInfo.validFrom }} until the
              {{ productInfo.validTo }}
            </div>
            <div v-else class="text-body-1">No dates set</div>
          </v-col>
          <v-col cols="2">
            <mex-btn
              v-if="checkIfProductInfoIsPlanned(productInfo)"
              tooltip="Product-Info is scheduled in the Future"
              icon="mdi-clock"
              icon-only
              color="blue"
            />
            <mex-btn
              v-else-if="checkIfProductInfoIsExpired(productInfo)"
              tooltip="Product-Info is Expired"
              icon="mdi-close"
              icon-only
              color="red"
            />
            <mex-btn
              v-else
              tooltip="Product-Info is currently Active"
              icon="mdi-check"
              icon-only
              color="green"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" cols="auto">
            <div class="text-body-1">Linked with {{ selectedClinics.length }} clinics</div>
          </v-col>
        </v-row>
        <v-row v-if="[imageStatus.EXIST, imageStatus.CHANGED].includes(productInfoImage.imageStatus)">
          <v-col>
            <v-expansion-panels :value="productInfoImage.imageSrc && editable ? [0] : []" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header @click="imageIsNotExpanded = !imageIsNotExpanded">
                  Full Image
                  <template v-slot:actions>
                    <v-icon v-if="imageIsNotExpanded">mdi-arrow-expand</v-icon>
                    <v-icon v-else>mdi-arrow-collapse</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-img v-if="productInfoImage.imageSrc" :src="productInfoImage.imageSrc"></v-img>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-form ref="productInfoForm" v-model="productInfoFromValid">
          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="productInfo.hyperlink"
                    :readonly="!editable"
                    :rules="[rules.required]"
                    class="mt-5"
                    color="primaryAccent"
                    dense
                    label="Hyperlink"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="productInfo.hyperlinkName"
                    :readonly="!editable"
                    :rules="[rules.required]"
                    color="primaryAccent"
                    dense
                    label="Hyperlink Name"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="productInfo.description"
                    :readonly="!editable"
                    dense
                    hide-details
                    label="ProductInfo Description"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row justify="center" class="mt-5">
                <v-date-picker
                  v-model="productInfoVadilityRange"
                  :allowed-dates="allowedDates"
                  :max="datePickerMax"
                  :min="datePickerMin"
                  :rules="[rules.required]"
                  color="primaryAccent"
                  dense
                  no-title
                  range
                  @change="productInfoVadilityRangeUpdate"
                ></v-date-picker>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-if="productInfoVadilityRange.length"
                    v-model="productInfoVadilityRange[0]"
                    class="mt-5"
                    color="primaryAccent"
                    dense
                    label="From"
                    outlined
                    readonly
                    clearable
                    @click:clear="clearFrom"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-if="productInfoVadilityRange.length > 1"
                    v-model="productInfoVadilityRange[1]"
                    class="mt-5"
                    color="primaryAccent"
                    dense
                    label="To"
                    outlined
                    readonly
                    clearable
                    @click:clear="clearTo"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row justify="center">
              </v-row>
              <v-row>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>{{ selectedClinics.length }} selected Clinics
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="10">
                          <v-autocomplete
                            v-model="selectedClinics"
                            :hint="`Clinics with version higher than ${piMinClinicVersion}`"
                            :items="clinicNames"
                            deletable-chips
                            dense
                            label="Clinics"
                            multiple
                            outlined
                            small-chips
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="1">
                          <mex-btn
                            icon="mdi-checkbox-multiple-marked"
                            icon-only
                            tooltip="Select all"
                            @click="selectAllClinics"
                          ></mex-btn>
                        </v-col>
                        <v-col cols="1">
                          <mex-btn
                            icon="mdi-checkbox-multiple-blank-outline"
                            icon-only
                            tooltip="Deselect all"
                            @click="deselectAllClinics"
                          ></mex-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
              <v-row v-if="editable" justify="center">
                <v-col
                  v-if="[imageStatus.DELETED, imageStatus.NOT_EXIST].includes(productInfoImage.imageStatus)"
                  cols="12"
                >
                  <v-file-input
                    v-model="productInfoImage.image"
                    :show-size="1000"
                    accept="image/png"
                    label="ProductInfo Image"
                    outlined
                    placeholder="ProductInfoImage"
                    @change="productInfoImageChanged"
                    @click:clear="clearProductInfoImage"
                  ></v-file-input>
                </v-col>
                <v-col v-else cols="auto">
                  <mex-btn
                    content="Remove ProductInfo Image"
                    icon="mdi-delete"
                    @click="clearProductInfoImage"
                  />
                </v-col>
              </v-row>
              <v-row v-if="[imageStatus.EXIST, imageStatus.CHANGED].includes(productInfoImage.imageStatus)">
                <v-col>
                  <v-expansion-panels
                    :value="productInfoImage.imageSrc && editable ? [0] : []"
                    multiple
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>Full Image</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-img
                          v-if="productInfoImage.imageSrc"
                          :src="productInfoImage.imageSrc"
                        ></v-img>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-row justify="center">
        <template v-if="!editable">
          <v-col>
            <mex-btn content="Edit" @click="startEditMode" />
          </v-col>
          <v-col>
            <v-spacer></v-spacer>
          </v-col>
          <v-col>
            <mex-btn content="Duplicate" @click="startDuplicationMode"></mex-btn>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="auto">
            <mex-btn
              :disabled="productInfoImage.imageStatus <= 0"
              :content="duplicating ? `Add Duplicate` : `Save`"
              @click="saveProductInfo"
            />
          </v-col>
          <v-col cols="auto">
            <mex-btn content="Cancel" @click="cancelProductInfo" />
          </v-col>
          <v-col cols="auto">
            <mex-btn v-if="productInfoId" disabled content="Delete" @click="deleteProductInfo" />
          </v-col>
        </template>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Chart from "chart.js";
import ImageUpload from "./ImageUpload.vue";
import productInfosService from "../../services/productInfos.service";
import MexSpermSpinner from "../MedITEX_Vue_Components/MexComponents/MexSpermSpinner.vue";
import ProfilePicUpload from "./ProfilePicUpload.vue";
import clinicsService from "../../services/clinics.service";
import MexBtn from "../MedITEX_Vue_Components/MexComponents/MexButton.vue";
import { checkIfProductInfoIsPlanned, checkIfProductInfoIsExpired } from "../../functions/checkProductInfoStatus";

export default {
  name: "ProductInfo",
  components: { MexBtn, ProfilePicUpload, MexSpermSpinner, ImageUpload },
  props: {
    productInfoId: {
      default: null
    },
    drugId: {
      default: null
    },
    drugName: {
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    duplicating: {
      type: Boolean,
      default: false
    },
    unavailableDateRanges: {
      type: Array,
      default: []
    },
    datePickerMax: null,
    datePickerMin: null
  },
  data() {
    return {
      productInfoFromValid: false,
      productInfoValidFromDateMenu: false,
      productInfoValidToDateMenu: false,
      isLoading: false,
      loadingText: "",
      editMode: false,
      productInfoVadilityRange: [],
      productInfo: {
        productInfoId: null,
        productInfoUUID: "",
        hyperlink: "",
        hyperlinkName: "",
        description: "",
        linkedClinics: [],
        validFrom: null,
        validTo: null
      },
      productInfoImage: {
        image: "",
        imageSrc: null,
        imageStatus: 0
      },
      clinicNames: [],
      selectedClinics: [],
      saveButtonClicked: false,
      rules: {
        required: v => !this.saveButtonClicked || (!!v || "Field is required"),
        validTimeRange: v => !this.saveButtonClicked || ((this.productInfo.validFrom <= v) || "Invalid date range")
      },
      imageIsNotExpanded: true,
      imageStatus: {
        DELETED: -1, // image was deleted in the current session --> requires change
        NOT_EXIST: 0, // no image exists, neither in database nor in this session --> doesn't require change
        EXIST: 1, // image is in database, but no change in this session --> doesn't require change
        CHANGED: 2 // image added or updated --> requires change
      },
      piMinClinicVersion: "3.0.1.1",
      selectedDates: []
    };
  },
  watch: {
    productInfoId(newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        this.fetchProductInfo();
      }
    },
    productInfoVadilityRange(newRange) {
      if(newRange.length === 1 && this.unavailableDateRanges.length) {
        const target = new Date(newRange[0]).getTime();
        let fromDiff = Infinity;
        let toDiff = Infinity;
        let nearestFromDate = null;
        let nearestToDate = null;

        for(const range of this.unavailableDateRanges) {
          if (new Date(range.from).getTime() > target) {
            let diff = Math.abs(target - new Date(range.from).getTime());
            if (diff < fromDiff) {
              fromDiff = diff;
              nearestFromDate = range.from
            }
          }
          if (new Date(range.from).getTime() < target) {
            let diff = Math.abs(new Date(range.from).getTime() - target);
            if (diff < toDiff) {
              toDiff = diff;
              nearestToDate = range.from
            }
          }
        }
        this.datePickerMax = nearestFromDate ? new Date(nearestFromDate).toISOString() : nearestFromDate;
        this.datePickerMin = nearestToDate ? new Date(nearestToDate).toISOString() : nearestToDate;
      }
      if (newRange.length === 0 && this.unavailableDateRanges.length) {
        this.datePickerMin = null;
        this.datePickerMax = null;
      }
    }
  },
  computed: {
    getProductInfoTitle() {
      if (this.productInfoId && this.editable) {
        return `Edit the ProductInfo for ${this.drugName}`;
      } else if (this.editable) {
        return `Create new ProductInfo for ${this.drugName}`;
      }
      return "";
    },
  },
  methods: {
    clearFrom() {
      this.productInfoVadilityRange = [];
    },
    clearTo() {
      this.productInfoVadilityRange = [this.productInfoVadilityRange[0]];
    },
    productInfoVadilityRangeUpdate() {
      if (new Date(this.productInfoVadilityRange[0]) > new Date(this.productInfoVadilityRange[1])) {
        this.productInfoVadilityRange = [this.productInfoVadilityRange[1], this.productInfoVadilityRange[0]];
      }
    },
    allowedDates(val) {
      for (const range of this.unavailableDateRanges) {
        if (new Date(val) >= new Date(range.from) && new Date(val) <= new Date(range.to)) {
          return false;
        }
      }
      return true;
    },
    // FETCHING AND SAVING PRODUCTINFOS --------------------------------------------------------------------------------------------------------------
    async fetchProductInfo() {
      if (this.productInfoId) {
        try {
          this.isLoading = true;
          this.loadingText = "Loading ProductInfo";
          await this.getUnavailableDateRanges();
          let response = await productInfosService.getProductInfoByID(this.productInfoId);
          this.productInfo = { ...response.data };
          this.productInfoId = this.productInfo.ProductInfoID;
          this.productInfo.validFrom = this.productInfo.validFrom && !this.duplicating ? this.$dateFormatter.convertJsonDate(this.productInfo.validFrom).date : null;
          this.productInfo.validTo = this.productInfo.validTo && !this.duplicating ? this.$dateFormatter.convertJsonDate(this.productInfo.validTo).date : null;
          this.productInfoVadilityRange.push(this.productInfo.validFrom);
          this.productInfoVadilityRange.push(this.productInfo.validTo);
          this.productInfoImage.imageStatus = this.productInfo.productInfoImagePath;
          this.selectedClinics = this.productInfo.ProductInfoClinics.map(x => x.ClinicID);
          if (this.productInfoImage.imageStatus === this.imageStatus.EXIST) {
            response = await productInfosService.getProductInfoImage(this.productInfoId);
            this.productInfoImage.image = response.data;
            this.productInfoImage.imageSrc = "data:image/png;base64," + this.productInfoImage.image;
          }
        } catch (err) {
          this.$toast[err.response.status === 404 ? "warning" : "error"](err.response.data.message);
          this.isLoading = false;
        } finally {
          this.isLoading = false;
        }
      }
    },
    async saveProductInfo() {
      this.saveButtonClicked = true;
      if (this.$refs.productInfoForm.validate()) {
        try {
          this.isLoading = true;
          this.loadingText = "Saving ProductInfo";
          this.productInfo.linkedClinics = this.selectedClinics;
          this.productInfo.validFrom = this.productInfoVadilityRange[0];
          this.productInfo.validTo = this.productInfoVadilityRange[1];
          let response = await productInfosService.saveProductInfo(this.productInfo, this.drugId, this.duplicating);
          if (this.productInfoImage.imageStatus === this.imageStatus.DELETED) {
            response = await productInfosService.deleteProductInfoImage(response.data.ProductInfoID);
          } else if (this.productInfoImage.imageStatus === this.imageStatus.CHANGED) {
            response = await productInfosService.saveProductInfoImage(this.productInfoImage, response.data.ProductInfoID);
          } else if (this.duplicating && this.productInfoImage.imageStatus === this.imageStatus.EXIST) {
            response = await productInfosService.duplicateProductInfoImage(this.productInfo.ProductInfoID, response.data.ProductInfoID);
          }
          this.$toast.success("ProductInfo saved");
          this.clearProductInfoForm();
          this.$emit("savedProductInfo");
        } catch (err) {
          this.$toast.error(err.response.data.message);
        } finally {
          this.isLoading = false;
        }
      }
      this.saveButtonClicked = false;
    },
    async getClinicNames() {
      try {
        this.isLoading = true;
        this.loadingText = "Loading clinic names";
        let response = await clinicsService.getClinicNames(18, this.piMinClinicVersion);
        this.clinicNames = [];
        response.data.forEach((clinic) => {
          this.clinicNames.push({
            value: clinic.ClinicID,
            text: clinic.name
          });
        });
      } catch (err) {
        this.$toast[err.response.status === 404 ? "warning" : "error"](err.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    async getUnavailableDateRanges() {
      try {
        this.isLoading = true;
        this.loadingText = "Loading unavailable date ranges";
        let response = await productInfosService.getUnavailableDateRangesForProductInfo(this.drugId, this.duplicating ? null : this.productInfoId);
        this.unavailableDateRanges = [...response.data.unavailableDateRanges];
      } catch (err) {
        this.$toast[err.response.status === 404 ? "warning" : "error"](err.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    // FORM ACTIONS ----------------------------------------------------------------------------------------------------------------------------------
    clearProductInfoForm() {
      this.productInfo.ProductInfoID = null;
      this.productInfo.hyperlink = "";
      this.productInfo.hyperlinkName = "";
      this.productInfo.description = "";
      this.productInfoImage.imageStatus = this.imageStatus.NOT_EXIST;
      this.productInfoImage.image = null;
      this.productInfoImage.imageSrc = null;
      this.productInfo.linkedClinics = [];
      this.productInfo.validFrom = null;
      this.productInfo.validTo = null;
      this.productInfoVadilityRange = [];
    },
    cancelProductInfo() {
      this.clearProductInfoForm();
      this.$emit("cancelProductInfo");
    },
    startEditMode() {
      this.$emit("startEditMode", this.productInfoId);
    },
    startDuplicationMode() {
      this.$emit("startDuplicateMode", this.productInfoId);
    },
    async deleteProductInfo() {
      try {
        this.isLoading = true;
        this.loadingText = "Deleting ProductInfo";
        await productInfosService.deleteProductInfo(this.productInfoId);
        this.$toast.success("ProductInfo successfully deleted");
        this.$emit("deletedProductInfo");
      } catch (err) {
        this.$toast[err.response.status === 404 ? "warning" : "error"](err.response.data.message);
      } finally {
        this.isLoading = false;
        this.clearProductInfoForm();
      }
    },
    productInfoImageChanged(event) {
      if (event && event.type !== 'image/png') {
        this.productInfoImage.image = null;
        this.$toast.error('Image needs to be of type PNG, received: ' + event.type);
      } else {
        this.productInfoImage.imageSrc = URL.createObjectURL(this.productInfoImage.image);
        this.productInfoImage.imageStatus = this.imageStatus.CHANGED;
      }
    },
    clearProductInfoImage() {
      this.productInfoImage.imageSrc = null;
      this.productInfoImage.imageStatus = this.imageStatus.DELETED;
    },
    selectAllClinics() {
      this.selectedClinics = [];
      this.clinicNames.forEach(x => this.selectedClinics.push(x.value));
    },
    deselectAllClinics() {
      this.selectedClinics = [];
    },
    clearDatePicker() {
      this.productInfoVadilityRange = [];
      this.datePickerMax = null;
      this.datePickerMin = null;
    },
    checkIfProductInfoIsExpired,
    checkIfProductInfoIsPlanned,
  },
  created() {
    this.fetchProductInfo();
    this.getClinicNames();
  }
};
</script>
