<template>
  <v-card
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    :color="$props.color"
  >
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn @click="removeImage" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-row>
      <v-virtual-scroll
        v-if="uploadedImage"
        :items="[uploadedImage]"
        height="150"
        item-height="50"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.name">
            <v-list-item-content>
              <v-list-item-title class="">
                {{ item.name }}
                <span class="ml-3 text--secondary">
                    {{ item.size ? String(item.size) + 'bytes' : '' }}
                  </span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click.stop="removeImage" icon>
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
      <v-row class="d-flex flex-column" dense align="center" justify="center">
        <v-img v-if="imageSrc" :src="imageSrc" max-width="120px" max-height="120px"></v-img>
        <v-icon v-else :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
          {{ dndAreaPlaceHolderIcon }}
        </v-icon>
        <mex-p :content="dndAreaText"/>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-file-input
        outlined
        :label="fileInputLabel"
        accept=".png"
        v-model="explorerUploadedImages"
        @change="explorerImageChanged"
      >
      </v-file-input>
    </v-card-actions>
  </v-card>
</template>

<script>

import MexBtn from "../MedITEX_Vue_Components/MexComponents/MexButton";
export default {
  name: "ImageUpload",
  components: {
    MexBtn
  },
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    imageSrc: {
      type: String,
      default: undefined,
    },
    dndAreaText: {
      type: String,
      default: "Drop image here..."
    },
    dndAreaPlaceHolderIcon: {
      type: String,
      default: "mdi-image"
    },
    fileInputLabel: {
      type: String,
      default: "... or select image from explorer"
    }
  },
  data() {
    return {
      dragover: false,
      uploadedImage: null,
      explorerUploadedImages: null,
    };
  },
  methods: {
    explorerImageChanged() {
      this.uploadedImage = this.explorerUploadedImages;
      this.explorerUploadedImages = null;
      this.imageSrc = URL.createObjectURL(this.uploadedImage);
      this.$emit('uploadedImage', this.uploadedImage);
    },
    onDrop(upload) {
      if (upload.dataTransfer.files.length > 1) {
        this.$toast.error('Only one file can be uploaded at a time');
      } else if (upload.dataTransfer.files.length) {
        if (upload.dataTransfer.files[0].name.split('.').pop() !== 'png') {
          this.$toast.error('Only PNG-files can be uploaded here');
          return;
        }
        this.uploadedImage = upload.dataTransfer.files[0];
        this.imageSrc = URL.createObjectURL(this.uploadedImage);
        this.$emit('uploadedImage', this.uploadedImage);
      }
    },
    removeImage() {
      this.uploadedImage = null;
      this.explorerUploadedImages = null;
      this.imageSrc = null;
      this.$emit('currentImageDeleted');
    },
  }
};
</script>

<style scoped></style>
