import httpClient from "../httpClient/httpClient";

const ProductInfosServices = {
  getProductInfoByDrugID(drugID) {
    return httpClient.get("api/productInfo-by-drug-id", {
      params: {
        drugID
      }
    });
  },
  getProductInfoByID(productInfoID) {
    return httpClient.get("api/productInfo-by-id", {
      params: {
        productInfoID
      }
    });
  },
  getProductInfoImage(productInfoID) {
    return httpClient.get("api/productInfo-image", {
      params: {
        productInfoID
      }
    });
  },
  deleteProductInfo(productInfoID) {
    return httpClient.post("api/delete-productInfo", {
      productInfoID
    });
  },
  saveProductInfo(
    productInfo,
    drugID,
    duplicating = false
  ) {
    return httpClient.post("api/save-product-info", {
      ...productInfo,
      drugID,
      duplicating
    });
  },
  saveProductInfoImage(productInfoImage, ProductInfoID, onUploadProgress) {
    const route = "api/upload-product-info-image";
    const formData = new FormData();
    formData.append("file", productInfoImage.image);
    formData.append("ProductInfoID", ProductInfoID);
    delete productInfoImage.image;
    return httpClient.post(route, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  },
  deleteProductInfoImage(productInfoID) {
    return httpClient.post("api/delete-product-info-image", {
      productInfoID
    });
  },
  getUnavailableDateRangesForProductInfo(drugID, productInfoID) {
    return httpClient.get("api/unavailable-date-ranges-productInfo", {
      params: {
        drugID,
        productInfoID
      }
    });
  },
  fetchProductInfos() {
    return httpClient.get("api/get-product-infos");
  },
  duplicateProductInfoImage(originProductInfoID, newProductInfoID) {
    return httpClient.post("api/duplicate-product-info-image", {
      originProductInfoID,
      newProductInfoID
    });
  }
};

export default ProductInfosServices;
